import React, { useState } from "react";
import "../styles/FlipCard.css";

const FlipCard = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [tempState, setTempState] = useState(false);

  const handleCardHover = (id) => {
    setHoveredCard(id);
    setTempState(true);
  };

  const Card = [
    {
      id: 1,
      img: require("../Images/Goal.png"),
      head: "Proven Track Records",
      des: "We have a successful track record of delivering high-quality IT solutions to clients across Europe and the United States, earning their trust and long-term partnerships.",
    },
    {
      id: 2,
      img: require("../Images/Team.png"),
      head: "Expert Team",
      des: "Our team comprises skilled professionals possessing extensive industry experience and a deep understanding of the European and US markets, ensuring our solutions align with client requirements and market trends.",
    },
    {
      id: 3,
      img: require("../Images/Q_S.png"),
      head: "Quality and Speed",
      des: "We adhere to rigorous quality standards to follow industry-recognized best practices to deliver solutions that can be reliable, secure, and compliant with relevant regulations.",
    },
    {
      id: 4,
      img: require("../Images/Customer.png"),
      head: "Customer-Centric Approach",
      des: "We prioritize our clients and their unique business needs. Our customer-centric approach ensures we build long-lasting relationships and consistently exceed client expectations.",
    },
    {
      id: 5,
      img: require("../Images/Cost.png"),
      head: "Cost-Effective Solutions",
      des: "We offer competitive pricing models that provide excellent value for money, enabling businesses to achieve their technology goals within budget.",
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          minWidth: "400px",
          justifyContent: "center",
        }}
      >
        {Card.map((item) => (
          <div
            key={item.id}
            className={`flip-card ${hoveredCard === item.id ? "flipped" : ""}`}
            onMouseEnter={() => handleCardHover(item.id)}
            onMouseLeave={() => setHoveredCard(null)}
          >
            <div
              className={`flip-card-inner ${
                hoveredCard === item.id ? "flipped" : ""
              }`}
            >
              <div
                className="flip-card-front"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img src={item.img} alt="Front Image" />
                <h3>{item.head}</h3>
              </div>
              <div className="flip-card-back">
                <p>{tempState ? item.des : ""}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FlipCard;
