import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading } from "../../components";
import CBSLandingPageTwoNavigations from "../../components/CBSLandingPageTwoNavigations";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";

export default function CBSGallerypagePage() {
  const [data, setData] = useState([]); 
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchTeamImages = async () => {
      try {
        // Fetch data from the API (assuming it returns an array of image URLs)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/gallery/`);
        setData(response.data);
        console.log("djaango",data)
        console.log(response.data);
        setLoading(false);  
      } catch (error) {
        console.error("Error fetching team images:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchTeamImages(); // Call the fetch function
  }, []);
  return (
    <>
      <Helmet>
        <title>Coherentbs</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex flex-col w-full gap-[70px] overflow-auto bg-white-A700">
      <header className="flex justify-between items-center p-[23px] sm:p-5 bg-white-A700">
          <div className="flex justify-center w-[19%] mt-2 sm:justify-normal">
              <Link to="/">
            <div className="flex justify-between w-full gap-5 sm:gap-2">
              <Img src="images/img_cbs_logo_1.png" alt="cbslogoone_one" className="w-[45%] object-contain" />
              <div className="flex flex-col">
                <Heading size="s" as="h5" className="tracking-[4.00px]">
                  COHERENT
                </Heading>
                <Text size="md" as="p" className="tracking-[-0.08px]">
                  Business Solutions
                </Text>
              </div>
            </div>
            </Link>
          </div>
          <CBSLandingPageTwoNavigations className="flex sm:flex-col justify-evenly items-center w-[37%] mt-2 mb-[15px] mr-[9px] gap-5" style={{alignItem:"center"}} />
        </header>
        <div className="w-full mx-auto md:p-5 max-w-[1301px]">
          <div className="flex flex-col items-center gap-24">
            <Heading size="4xl" as="h1" className="!text-transparent text-center bg-gradient1 bg-clip-text">
              Leading companies trust us to develop software
            </Heading>
          
           
            <div className="flex flex-col self-stretch items-center" >
              <Img
                src="images/Trip.jpeg"
                alt="image"
                className="self-stretch h-[593px] w-full object-cover md:h-[280px]"
                />
              <Text size="xl" as="p" className="w-[64%] md:w-full mt-6 text-center !leading-10">
                Happy Faces From the monsoon outing
              </Text>
               
              <div className="flex md:flex-col self-stretch justify-center items-center mt-0"  >
                
  <div className="flex flex-col w-full mb-[10px] gap-[15px] items-center" >
    <Img src="images/awake.jpeg" alt="image_one" className="w-[88%] h-[450px] md:h-[260px] object-cover " />
    <Text size="xl" as="p" className=" md:ml-0 text-center !leading-10 w-[76%]">
    Dinner party after succesfull app launch of Awake!
    </Text>
  </div>
  <div className="flex flex-col w-full mt-[171px] gap-[35px] items-left">
    <Text size="xl" as="p" className="w-[76%] md:w-full text-center !leading-10">
Diwali celebration in office 
    </Text>
    <Img src="images/Pooja.jpeg" alt="image_two" className="h-[550px] w-[88%]  md:h-[240px] md:w-[100%] object-cover" />

  </div>
</div>


              <div className="flex md:flex-col mt-[102px] gap-[101px]">
                <div className="flex flex-col items-center w-full mb-[13px] gap-[35px]">
                  <Img
                    src="images/Wayam.jpeg"
                    alt="image"
                    className="h-[600px] w-full md:w-full md:h-[280px] object-cover"
                  />
                 
                </div>
                <div className="flex flex-col items-center w-full gap-12">
                  <Img
                    src="images/Wayam2.jpeg"
                    alt="image"
                    className="h-[600px] w-full md:w-[600px] md:h-[280px] object-cover"
                  />
               
                </div>
                
              </div>
              <div style={{display:"flex",width:"100%" ,flexDirection:"row", alignItems:"center",justifyContent:"center"}}>
                <Text size="xl" as="p" className="w-[82%] md:w-full text-center !leading-10 mt-10">
                Wayam is an edutainment magazine for kids. Team Coherent was happy to collaborate with Wayam to develop an app- for online user engagement. The launch was done at Thane by the hands of great scientist and padmavibhushan awardee- Dr. Anil Kakodkar. Many renowned people such as Praveen Davane, Rajeev Tambe, Uday Nirgudkar were also present.
                  </Text>
                  </div> 
           
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}