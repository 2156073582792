import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Img, Heading, Text, Button } from "../../components";
import CBSLandingPageTwoNavigations from "../../components/CBSLandingPageTwoNavigations";
import CBSLandingPageTwoTeachstack from "../../components/CBSLandingPageTwoTeachstack";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import FlipCard from "components/FlipCard";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CBSLandingpageTwoPage({
  backend = "Backend",
  frontend = "Frontend",
  databases = "Databases",

  cloudtesting = "CloudTesting",
  devops = "DevOps",
  ...props
}) {
  const [activeText, setActiveText] = useState(backend);
  const navigate = useNavigate();
  const handleTextClick = (text) => {
    setActiveText(text);
  };
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: "(min-width: 1224px)",
  // });

  const images = [
    "images/Convene chat.png",
    "images/LMS.png",
    "images/Ticketing.png",
    "images/Convene VC.png",
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const handelContact = () => {
    navigate("/cbscontactpage");
  };
  return (
    <>
      <Helmet>
        <title>Coherentbs</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      {/* {isDesktopOrLaptop && ( */}
      <div className="w-full overflow-auto bg-white-A700">
        <header className="flex justify-between items-center p-[23px] sm:p-5 bg-white-A700">
          <div className="flex justify-center w-[19%] mt-2 sm:justify-normal">
            <Link to="/">
              <div className="flex justify-between w-full gap-5 sm:gap-2">
                <Img
                  src="images/img_cbs_logo_1.png"
                  alt="cbslogoone_one"
                  className="w-[45%] object-contain"
                />
                <div className="flex flex-col">
                  <Heading size="s" as="h5" className="tracking-[4.00px]">
                    COHERENT
                  </Heading>
                  <Text size="md" as="p" className="tracking-[-0.08px]">
                    Business Solutions
                  </Text>
                </div>
              </div>
            </Link>
          </div>
          <CBSLandingPageTwoNavigations
            className="flex sm:flex-col justify-evenly items-center w-[37%] mt-2 mb-[15px] mr-[9px] gap-5"
            style={{ alignItem: "center" }}
          />
        </header>
        <div>
          <div>
            <div className="flex flex-col gap-[100px] md:gap-[79px] sm:gap-[59px]">
              <div className="h-[573px] md:h-[110px] ">
                <div style={{ flex: 1 }}>
                  <Img
                    src="images/img_Landing.png"
                    alt="heroimage_one"
                    className="left-0 bottom-0 right-0 top-0 m-auto object-contain"
                  />

                  <div
                    style={{
                      position: "absolute",
                      top: "30%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      textAlign: "center",
                    }}
                    className="sm-top-40 sm-left-40 visiblity sm:hidden"
                  >
                    <p
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 700,
                        fontSize: "30px",
                        lineHeight: "43.57px",
                        backgroundImage:
                          "Linear-gradient(103deg, #2857AF 10%, #EF7F1B 40%, #9BBEFF 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        backgroundClip: "text",
                        marginBottom: "32px",
                        maxWidth: "90%", // Adjust the maximum width as needed
                        margin: "0 auto", // Center align the text
                      }}
                      className="responsive-text sm-font-20"
                    >
                      DISCOVER A WORLD OF OPPORTUNITIES
                    </p>
                    <p
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontSize: "18px",
                        color: "#FFFFFF",
                        maxWidth: "90%",
                        margin: "0 auto",
                        marginTop: 10, // Center align the text
                      }}
                      className="responsive-text sm-font-16"
                    >
                      We empower a diverse IT service-oriented workforce where
                      strong individuals are unified by a clear common purpose
                      to deliver excellence and a high level of customer
                      satisfaction.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="flex md:flex-col justify-center items-center w-full gap-[22px] mx-auto md:p-5 max-w-[1435px]">*/}
              <div className="flex md:flex-col justify-center items-center gap-[22px] ">
                {/* <Button size="sm" shape="square" className="w-[40px]">
          <Img src="images/img_icon.svg" />
        </Button>
         */}
                <div
                  style={{
                    width: "70%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Slider {...settings}>
                    {images.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image}
                          alt={`Image ${index + 1}`}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto",
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                  {/* </div>*/}
                </div>
                {/* <Img
        src="images/img_arrow_right.svg"
        alt="arrowright_one"
        className="h-[40px] w-[40px]"
      /> */}
              </div>
              <div className="flex md:flex-col justify-center items-center w-full mx-auto md:p-5 max-w-[1219px]">
                <div className="flex flex-col gap-[23px] md:gap-3">
                  <Heading
                    size="7xl"
                    as="h1"
                    className="!text-yellow-900 text-center sm:text-3xl md:text-4xl"
                  >
                    20+
                  </Heading>
                  <Text
                    size="4xl"
                    as="p"
                    className="!text-indigo-900 text-center sm:text-3xl md:text-4xl"
                  >
                    Projects{" "}
                  </Text>
                </div>
                <div className="self-center h-[102px] w-[4px] md:w-[102px] md:h-[4px] bg-gradient3 justify-center ml-[60px] md:ml-[0px]" />
                <div className="flex flex-col items-center ml-[60px] md:ml-[0px] gap-5 md:gap-3">
                  <Heading
                    size="7xl"
                    as="h2"
                    className="!text-yellow-900 text-center sm:text-3xl md:text-4xl"
                  >
                    40+
                  </Heading>
                  <Text
                    size="4xl"
                    as="p"
                    className="!text-indigo-900 text-center sm:text-3xl md:text-4xl"
                  >
                    Clients
                  </Text>
                </div>
                <div className="self-center h-[102px] w-[4px] md:w-[102px] md:h-[4px] bg-gradient3 ml-[60px] md:ml-[0px]" />
                <div className="flex flex-col ml-[60px] md:ml-[0px] md:gap-3 gap-5">
                  <Heading
                    size="7xl"
                    as="h3"
                    className="!text-yellow-900 text-center sm:text-3xl md:text-4xl"
                  >
                    5+
                  </Heading>
                  <Text
                    size="4xl"
                    as="p"
                    className="!text-indigo-900 text-center sm:text-3xl md:text-4xl"
                  >
                    Years
                  </Text>
                </div>

                <div className="self-center h-[102px] w-[4px] md:w-[102px] md:h-[4px] bg-gradient3 ml-[60px] md:ml-[0px]" />
                <div className="flex flex-col ml-[60px] gap-5 md:gap-3 md:ml-[0px]">
                  <Heading
                    size="7xl"
                    as="h3"
                    className="!text-yellow-900 text-center sm:text-3xl md:text-4xl"
                  >
                    3
                  </Heading>
                  <Text
                    size="4xl"
                    as="p"
                    className="!text-indigo-900 text-center sm:text-3xl md:text-4xl"
                  >
                    Locations
                  </Text>
                </div>
              </div>
            </div>
            <div
              className="w-full mt-[100px] mx-auto md:p-5 max-w-[1823px] visible sm:hidden md:hidden"
              style={{ overflowX: "auto" }}
            >
              <div className="h-[602px] relative ">
                <div className="justify-center h-[602px] w-full left-0 bottom-0 right-0 top-0 m-auto absolute">
                  <div className="justify-center h-[602px] w-[100%] left-0 bottom-0 right-0 top-0 m-auto bg-gradient2 absolute" />
                  <div className="flex flex-col items-center w-[full] gap-[21px] top-[5%] right-0 left-0 m-auto absolute">
                    <div className="h-[5px] w-[7%] bg-white-A700 rounded-sm" />
                    <div
                      className="flex justify-center w-full"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <Heading
                        size="4xl"
                        as="h5"
                        className="!text-indigo-900  justify-center "
                      >
                        Services We Offer
                      </Heading>
                    </div>
                  </div>
                  <div className="flex md:flex-col gap-10 mx-auto w-[95%] ">
                    <div
                      className="flex flex-col justify-center w-[50%] h-[295px] mt-[200px] gap-4 p-4 md:mt-0 border border-solid yellow_900_indigo_600_border bg-'#FFFFFF' "
                      style={{ backgroundColor: "#FFFFFF", zIndex: 5 }}
                    >
                      <div className="flex flex-col items-start mt-5 gap-[22px]">
                        <div
                          style={{
                            height: "50px",
                            width: "50px",
                            border: "1px solid #2857AF",
                            borderRadius: "50%",
                          }}
                        />
                        <Heading
                          size="s"
                          as="h6"
                          className="!font-semibold"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItem: "left",
                            justifyContent: "left",
                          }}
                        >
                          AI-Based Services
                        </Heading>
                      </div>
                      <Text as="p" className="mb-[21px] !leading-[162.02%]">
                        Harnessing the power of artificial intelligence, we
                        deliver cutting-edge solutions that optimize processes,
                        drive efficiency, and unlock new possibilities for your
                        business.
                      </Text>
                    </div>
                    <div
                      className="flex flex-col justify-center w-[50%] h-[295px]  mt-[200px] gap-4 p-4 md:mt-0 border border-solid yellow_900_indigo_600_border bg-'#FFFFFF' "
                      style={{ backgroundColor: "#FFFFFF", zIndex: 5 }}
                    >
                      <div className="flex flex-col items-start mt-5 gap-[22px]">
                        <div
                          style={{
                            height: "50px",
                            width: "50px",
                            border: "1px solid #2857AF",
                            borderRadius: "50%",
                          }}
                        />

                        <Heading
                          size="s"
                          as="h6"
                          className="!font-semibold"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItem: "left",
                            justifyContent: "left",
                          }}
                        >
                          Mobile App Development
                        </Heading>
                      </div>
                      <Text as="p" className="mb-[21px] !leading-[162.02%]">
                        We craft innovative mobile solutions tailored to your
                        needs, ensuring seamless user experiences across iOS and
                        Android platforms.
                      </Text>
                    </div>
                    <div
                      className="flex flex-col justify-center w-[50%] h-[295px]   mt-[200px]  gap-4 p-4 md:mt-0 border border-solid yellow_900_indigo_600_border bg-'#FFFFFF' "
                      style={{ backgroundColor: "#FFFFFF", zIndex: 5 }}
                    >
                      <div className="flex flex-col items-start mt-[22px] gap-[18px] justify-center self-ceneter">
                        <div
                          style={{
                            height: "50px",
                            width: "50px",
                            border: "1px solid #EF7F1B",
                            borderRadius: "50%",
                          }}
                        />
                        <Heading
                          size="s"
                          as="h5"
                          className="!text-yellow-900 !font-semibold justify-center self-ceneter"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItem: "left",
                            justifyContent: "left",
                          }}
                        >
                          Web Design & Development
                        </Heading>
                      </div>
                      <Text as="p" className="mb-[23px] !leading-[162.02%]">
                        From captivating designs to responsive development, we
                        build websites that not only engage your audience but
                        also drive business growth.
                      </Text>
                    </div>
                    <div
                      className="flex flex-col justify-center w-[50%] h-[295px]   mt-[200px]  gap-4 p-4 md:mt-0 border border-solid yellow_900_indigo_600_border bg-'#FFFFFF' "
                      style={{ backgroundColor: "#FFFFFF", zIndex: 5 }}
                    >
                      {" "}
                      <div className="flex flex-col items-start mt-5 gap-[22px]">
                        <div
                          style={{
                            height: "50px",
                            width: "50px",
                            border: "1px solid #2857AF",
                            borderRadius: "50%",
                            marginTop: "10px",
                          }}
                        />

                        <Heading
                          size="s"
                          as="h6"
                          className="!font-semibold"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItem: "left",
                            justifyContent: "left",
                          }}
                        >
                          Software Testing Service
                        </Heading>
                      </div>
                      <Text as="p" className="mb-[21px] !leading-[162.02%]">
                        Our meticulous testing processes guarantee the
                        reliability and performance of your software, ensuring a
                        flawless user experience at every interaction.
                      </Text>
                    </div>
                    <div
                      className="flex flex-col justify-center w-[50%] h-[295px]   mt-[200px]  gap-4 p-4 md:mt-0 border border-solid yellow_900_indigo_600_border bg-'#FFFFFF' "
                      style={{ backgroundColor: "#FFFFFF", zIndex: 5 }}
                    >
                      <div className="flex flex-col items-start mt-5 gap-[22px]">
                        <div
                          style={{
                            height: "50px",
                            width: "50px",
                            border: "1px solid #2857AF",
                            borderRadius: "50%",
                          }}
                        />

                        <Heading
                          size="s"
                          as="h6"
                          className="!font-semibold"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItem: "left",
                            justifyContent: "left",
                          }}
                        >
                          Consultancy
                        </Heading>
                      </div>
                      <Text as="p" className="mb-[21px] !leading-[162.02%]">
                        Leveraging our expertise across industries, we offer
                        strategic consultancy services to empower your business
                        with tailored solutions and actionable insights.
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full mt-[50px] md:mt-[28px] px-9 mx-auto md:p-3 sm:px-5 max-w-[1589px] h-[400px] ">
              <div className="flex md:flex-col items-start">
                <div className="flex flex-col items-center justify-center mb-[50px]  pb-[50px] md:pb-5 flex-1">
                  <div className="flex justify-center w-[45%] md:w-full">
                    <div className="flex flex-col items-center w-full gap-5">
                      <div className="h-[5px] w-[15%] bg-gradient1 rounded-sm" />
                      <Text
                        size="5xl"
                        as="p"
                        className="flex !text-transparent text-center bg-gradient1 bg-clip-text"
                      >
                        <span className="text-indigo-900">
                          <>
                            Our &nbsp;
                            <br />
                          </>
                        </span>
                        <span className="text-indigo-900 font-bold">
                          Expertise
                        </span>
                      </Text>
                    </div>
                  </div>
                  {/* <CBSLandingPageTwoTeachstack className="flex justify-center items-center w-[72%] md:w-full mt-[30px] pt-1" />
                  <Img src="images/img_logos.png" alt="logos_one" className="w-[58%] mt-[86px] object-cover" /> */}
                  <div className="flex flex-col w-[70%]  h-[70%] mt-[40px] ">
                    <div className="flex sm:flex-col justify-between gap-5">
                      <div>
                        <Text
                          as="p"
                          className={`self-start cursor-pointer ${
                            activeText === backend
                              ? "!text-transparent bg-gradient1 bg-clip-text"
                              : ""
                          }`}
                          onClick={() => handleTextClick(backend)}
                        >
                          {backend}
                        </Text>
                        {activeText === backend && (
                          <div className="h-[3px] w-[100%] bg-gradient1 rounded-[1px] mt-1 self-start" />
                        )}
                      </div>

                      <div>
                        <Text
                          as="p"
                          className={`self-start cursor-pointer ${
                            activeText === frontend
                              ? "!text-transparent bg-gradient1 bg-clip-text"
                              : ""
                          }`}
                          onClick={() => handleTextClick(frontend)}
                        >
                          {frontend}
                        </Text>
                        {activeText === frontend && (
                          <div className="h-[3px] w-[100%] bg-gradient1 rounded-[1px] mt-1 self-start" />
                        )}
                      </div>
                      <div>
                        <Text
                          as="p"
                          className={`self-start cursor-pointer ${
                            activeText === databases
                              ? "!text-transparent bg-gradient1 bg-clip-text"
                              : ""
                          }`}
                          onClick={() => handleTextClick(databases)}
                        >
                          {databases}
                        </Text>
                        {activeText === databases && (
                          <div className="h-[3px] w-[100%] bg-gradient1 rounded-[1px] mt-1 self-start" />
                        )}
                      </div>

                      <div>
                        <Text
                          as="p"
                          className={`self-start cursor-pointer ${
                            activeText === cloudtesting
                              ? "!text-transparent bg-gradient1 bg-clip-text"
                              : ""
                          }`}
                          onClick={() => handleTextClick(cloudtesting)}
                        >
                          {cloudtesting}
                        </Text>
                        {activeText === cloudtesting && (
                          <div className="h-[3px] w-[100%] bg-gradient1 rounded-[1px] mt-1 self-start" />
                        )}
                      </div>
                      <div>
                        <Text
                          as="p"
                          className={`self-start cursor-pointer ${
                            activeText === devops
                              ? "!text-transparent bg-gradient1 bg-clip-text"
                              : ""
                          }`}
                          onClick={() => handleTextClick(devops)}
                        >
                          {devops}
                        </Text>
                        {activeText === devops && (
                          <div className="h-[3px] w-[100%] bg-gradient1 rounded-[1px] mt-1 self-start" />
                        )}
                      </div>
                    </div>
                  </div>
                  {activeText === backend && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        flexDirection: "row",
                        gap: "50px",
                        marginTop: "70px",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <div style={{ width: "10%", height: "15%" }}>
                        <img
                          src="images/Nodejs.png"
                          alt="Node.js"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Django.png"
                          alt="Django"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Graphql.png"
                          alt="Graphql"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Nhost.png"
                          alt="Graphql"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    </div>
                  )}

                  {activeText === frontend && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "10%",
                        flexDirection: "row",
                        gap: "50px",
                        marginTop: "70px",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/React.png"
                          alt="Node.js"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/JavaScript.png"
                          alt="JavaScript"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    </div>
                  )}

                  {activeText === databases && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "10%",
                        flexDirection: "row",
                        gap: "50px",
                        marginTop: "70px",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Postgras.png"
                          alt="Node.js"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Mysql.png"
                          alt="Django"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Mongo.png"
                          alt="Django"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    </div>
                  )}
                  {activeText === devops && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "10%",
                        flexDirection: "row",
                        gap: "50px",
                        marginTop: "70px",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Nginx.png"
                          alt="Node.js"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Tensor.png"
                          alt="Django"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Docker.png"
                          alt="Docker"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Jenkins.png"
                          alt="Jenkins"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    </div>
                  )}
                  {activeText === cloudtesting && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "10%",
                        flexDirection: "row",
                        gap: "50px",
                        marginTop: "70px",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Azure.png"
                          alt="Azure"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div style={{ width: "10%", height: "10%" }}>
                        <img
                          src="images/Computer.png"
                          alt="Azure"
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-[30px] gap-[71px]">
              <div className="w-full mx-auto md:p-5 max-w-[1307px]">
                <div className="flex flex-col items-center">
                  <div className="h-[5px] w-[5%] bg-gradient1 rounded-sm" />
                  <Text
                    size="5xl"
                    as="p"
                    className="flex w-[full] mt-5 !text-indigo-900 text-center"
                  >
                    <span className="text-indigo-900">
                      <>
                        Why &nbsp;
                        <br />
                      </>
                    </span>
                    <span className="text-indigo-900 font-bold">Us?</span>
                  </Text>
                  <FlipCard />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer className="mt-[69px]" />
      </div>
      {/* )} */}
    </>
  );
}
