import React, { useState, useEffect } from "react";
import { Button, Heading } from "./..";
import { useLocation, Link, useNavigate } from "react-router-dom";

export default function CBSLandingPageTwoNavigations({
  home = "Home",
  about = "About",
  solutions = "Solutions",
  gallery = "Gallery",
  contactUs = "Contact us",
  ...props
}) {
  const location = useLocation();
  const [selectedHeading, setSelectedHeading] = useState(null);

  useEffect(() => {
    
    if (location.pathname === "/" && selectedHeading !== "home") {
      setSelectedHeading("home");
    } else if (location.pathname === "/cbsaboutpage" && selectedHeading !== "about") {
      setSelectedHeading("about");
    } else if (location.pathname === "/cbssolutionspage" && selectedHeading !== "solutions") {
      setSelectedHeading("solutions");
    } else if (location.pathname === "/cbsgallerypage" && selectedHeading !== "gallery") {
      setSelectedHeading("gallery");
    }
  }, [location.pathname, selectedHeading]);

const navigate=useNavigate();

  const handleAboutus=()=>{
    navigate("/cbscontactpage")
  }
  return (
    <div {...props}>
      <Link to="/">
        <Heading
          as="h2"
          className="text-center"
          style={{ borderBottom: selectedHeading === "home" ? '3px solid #EF7F1B' : 'none' }}
          onClick={() => setSelectedHeading("home")}
        >
          {home}
        </Heading>
      </Link>
      <Link to="/cbsaboutpage">
        <Heading
          as="h2"
          className="text-center"
          style={{ borderBottom: selectedHeading === "about" ? '3px solid #EF7F1B' : 'none' }}
          onClick={() => setSelectedHeading("about")}
        >
          {about}
        </Heading>
      </Link>
      <Link to="/cbssolutionspage">
        <Heading
          as="h2"
          className="text-center"
          style={{ borderBottom: selectedHeading === "solutions" ? '3px solid #EF7F1B' : 'none' }}
          onClick={() => setSelectedHeading("solutions")}
        >
          {solutions}
        </Heading>
      </Link>
      <Link to="/cbsgallerypage">
        <Heading
          as="h2"
          className="text-center"
          style={{ borderBottom: selectedHeading === "gallery" ? '3px solid #EF7F1B' : 'none' }}
          onClick={() => setSelectedHeading("gallery")}
        >
          {gallery}
        </Heading>
      </Link>
      <Button onClick={handleAboutus}
        variant="gradient"
        shape="round"
        color="yellow_900_indigo_600"
        className="sm:px-5 font-semibold min-w-[153px]"
      >
        {contactUs}
      </Button>
    </div>
  );
}
