import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  TextArea,
  Text,
  Input,
  Img,
  GoogleMap,
  Heading,
} from "../../components";
import CBSLandingPageTwoNavigations from "../../components/CBSLandingPageTwoNavigations";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import axios from "axios";

export default function CBSContactpagePage() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false); // It's good practice to handle loading states for user experience.
  const [error, setError] = useState(null); // Handling errors to give feedback to the user.

    // send email function
    async function sendemail() {
      try {
        const response = await fetch('https://ziuetfnxlyjlcvdiraet.functions.ap-south-1.nhost.run/v1/coherentbsform', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({})
        });
    
        if (!response.ok) {
          throw new Error('Failed to send verification email');
        }
    
        // console.log('Verification email sent successfully');
      } catch (error) {
        console.error('Error sending verification email:', error);
      }
    }
    
  const handleChange = (key, e) => {
    // Add this line to debug
    console.log(key, e);
    setData({
      ...data,
      [key]: e,
    });
  };
  const validateForm = () => {
    // Basic validation
    if (!data.first_name || !data.last_name || !data.email || !data.message) {
      setError("Please fill in all required fields.");
      return false;
    }
    return true; // Form is valid
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stops the form submission if validation fails
    }

    setLoading(true);
    setError(null);
    console.log("first", data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contact-us/`,
        data
      );

      console.log("Data inserted successfully:", response.data);
      setData({
        first_name: "",
        last_name: "",
        email: "",
        message: "",
      });
      setLoading(false);
      alert("Your message has been sent successfully!");
      sendemail()
    } catch (error) {
      console.error("Error inserting data:", error);
      setLoading(false);
      setError(
        "An error occurred while submitting the form. Please try again later."
      );
    }
  };


  return (
    <>
      <div className="flex flex-col w-full gap-[19px] overflow-auto bg-white-A700">
        <header className="flex justify-between items-center p-[23px] sm:p-5 bg-white-A700">
          <div className="flex justify-center w-[19%] mt-2 sm:justify-normal">
            <Link to="/">
              <div className="flex justify-between w-full gap-5 sm:gap-2">
                <Img
                  src="images/img_cbs_logo_1.png"
                  alt="cbslogoone_one"
                  className="w-[45%] object-contain"
                />
                <div className="flex flex-col">
                  <Heading size="s" as="h5" className="tracking-[4.00px]">
                    COHERENT
                  </Heading>
                  <Text size="md" as="p" className="tracking-[-0.08px]">
                    Business Solutions
                  </Text>
                </div>
              </div>
            </Link>
          </div>
          <CBSLandingPageTwoNavigations
            className="flex sm:flex-col justify-evenly items-center w-[37%] mt-2 mb-[15px] mr-[9px] gap-5"
            style={{ alignItem: "center" }}
          />
        </header>

        <div className="flex flex-col items-center w-full mx-auto md:p-5 max-w-[1440px]">
          <div className="flex self-stretch justify-center mt-[18px] px-14 py-[79px] md:p-5 md:mt-[1px] bg-gradient2">
            <div className="flex flex-col items-center w-[51%] mt-[11px]">
              <div className="flex flex-col self-stretch mt-[27px] gap-[25px] md:self-center">
                <Text
                  size="3xl"
                  as="p"
                  className=" flex justify-center items-center w-[100%] "
                  style={{ color: "#000000", fontSize: "30px" }}
                >
                  Say Hello
                </Text>
                <div className="flex md:flex-col justify-center gap-[27px]">
                  <div className="flex flex-col w-full gap-[9px]">
                    <Text
                      as="p"
                      className="!text-gray-800 tracking-[-0.30px] !font-medium"
                    >
                      First Name
                    </Text>
                    <Input
                      shape="round"
                      placeholder="First Name"
                      value={data.first_name}
                      onChange={(e) => handleChange("first_name", e)}
                    />
                  </div>
                  <div className="flex flex-col w-full gap-[9px]">
                    <Text
                      as="p"
                      className="!text-gray-800 tracking-[-0.30px] !font-medium"
                    >
                      Last Name
                    </Text>
                    <Input
                      shape="round"
                      name="last_name"
                      value={data?.last_name}
                      placeholder="Last Name"
                      onChange={(e) => handleChange("last_name", e)}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1.5">
                  <Text
                    as="p"
                    className="!text-gray-800 tracking-[-0.30px] !font-medium"
                  >
                    Email Address
                  </Text>
                  <Input
                    shape="round"
                    name="email"
                    value={data?.email}
                    placeholder="Email"
                    onChange={(e) => handleChange("email", e)}
                  />
                </div>
                <div className="flex flex-col gap-[5px]">
                  <Text
                    as="p"
                    className="!text-gray-800 tracking-[-0.30px] !font-medium"
                  >
                    Message
                  </Text>
                  <TextArea
                    shape="round"
                    name="message"
                    placeholder="Message"
                    value={data?.message}
                    o
                    onChange={(e) => handleChange("message", e)}
                  />
                </div>
                <div className="flex flex-col gap-[5px]">
                  <Text
                    as="p"
                    className="!text-gray-800 tracking-[-0.30px] !font-medium"
                  >
                    Upload Resume
                  </Text>
                  <input
              type="file"
              accept=".pdf,.doc,.docx"
              
            />
            </div>
              </div>
              <Button
                color="yellow_900"
                shape="round"
                className="self-end mt-6 sm:px-5 font-semibold min-w-[153px]"
                onClick={handleSubmit}
              >
                Get In Touch
              </Button>
            </div>
          </div>

          <div className="h-[537px] w-[74%] md:h-[40px]">
            <Img src="images/Global.png" alt="gpsone_one" />
          </div>
          <div className="flex md:flex-col justify-between items-center w-[61%] md:w-full mt-28 gap-5">
            <div className="flex sm:flex-col justify-between items-center w-[56%] md:w-full gap-5">
              <div className="flex sm:flex-col self-end justify-center items-start w-[78%] sm:w-full gap-[27px]">
                <Img
                  src="images/img_gps_1.svg"
                  alt="gpsone_one"
                  className="h-[29px] w-[29px] mt-2.5"
                />
                <Text
                  size="3xl"
                  as="p"
                  className="w-[92%] sm:w-full !text-indigo-900 !leading-[30px]"
                >
                  Coherent Business Solutions 218, Karan Plams, S.No.107/7B,
                  Warje, Pune 411 058
                </Text>
              </div>
              <div className="h-full w-px sm:w-full sm:h-px bg-gray-400" />
            </div>
            <div className="flex justify-center items-center gap-[25px]">
              <Img
                src="images/img_group.svg"
                alt="image"
                className="h-[30px] w-[30px]"
              />
              <a href="#">
                <Text size="3xl" as="p" className="!text-indigo-900">
                  + 91 88054 06200
                </Text>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
