import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Heading, Text } from "../../components";
import CBSLandingPageTwoNavigations from "../../components/CBSLandingPageTwoNavigations";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios
import { Link } from "react-router-dom";

export default function CBSaboutpagePage() {
  const navigate = useNavigate();
  // console.log(process.env.REACT_APP_API_URL + "/about");
  const handleConnect = () => {
    navigate("/cbscontactpage");
  };
  const [teamImages, setTeamImages] = useState([]); // State to store team images
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchTeamData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/about/`
        );
        setData(response.data);
        setLoading(false);
        console.log(response.data, "dataaa ");
      } catch (error) {
        console.error("Error fetching team data:", error);
        setLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  const imageurl = `${process.env.REACT_APP_API_URL}`;

  console.log(imageurl + data[0]?.image_url, "dataaaa");
  const url1 = imageurl + data[0]?.image_url;

  const first_data = data[1];
  console.log(first_data?.image_url, "first_data");

  const imagetobe = `${process.env.REACT_APP_API_URL}${first_data?.image_url}`;
  console.log(imagetobe, "imagetobe");

  return (
    <>
      <Helmet>
        <title>Coherentbs</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col w-full gap-[70px] md:gap-[19px] overflow-auto bg-white-A700">
        <header className="flex justify-between items-center p-[23px] sm:p-5 bg-white-A700">
          <div className="flex justify-center w-[19%] mt-2 sm:justify-normal">
            <Link to="/">
              <div className="flex justify-between w-full gap-5 sm:gap-2">
                <Img
                  src="images/img_cbs_logo_1.png"
                  alt="cbslogoone_one"
                  className="w-[45%] object-contain"
                />
                <div className="flex flex-col">
                  <Heading size="s" as="h5" className="tracking-[4.00px]">
                    COHERENT
                  </Heading>
                  <Text size="md" as="p" className="tracking-[-0.08px]">
                    Business Solutions
                  </Text>
                </div>
              </div>
            </Link>
          </div>
          <CBSLandingPageTwoNavigations
            className="flex sm:flex-col justify-evenly items-center w-[37%] mt-2 mb-[15px] mr-[9px] gap-5"
            style={{ alignItem: "center" }}
          />
        </header>
        <div className="flex flex-col items-center">
          <div className="h-[742px] w-[80%] md:h-[520px] relative">
            <Heading
              size="4xl"
              as="h1"
              className="w-[100%] left-0 top-0 m-auto !text-transparent bg-gradient1 bg-clip-text !leading-[55px] absolute sm:text-2xl md:text-3xl"
            >
              Leading companies trust us to develop software
            </Heading>
            <Img
              src="images/img_group_21_yellow_900.svg"
              alt="image"
              className="h-[154px] right-0 top-[14%] visible sm:hidden md:hidden m-auto absolute  "
            />
            <Img
              src="images/img_image.png"
              alt="image_two"
              className="h-[561px] w-[100%] bottom-0 left-0 m-auto object-cover absolute md:h-[361px]"
            />
          </div>
          <div className="flex flex-col items-center w-full mt-[100px] md:mt-[50px] sm:mt-[0px] gap-[31px] mx-auto md:p-5 max-w-[1234px]">
            <div className="h-[5px] w-[6%] bg-gradient1 rounded-sm" />
            <Heading
              size="5xl"
              as="h2"
              className="!text  -indigo-900 text-center"
            >
              About Us
            </Heading>
            <Text size="xl" as="p" className="text-justify !leading-10 md:!leading-7">
              Coherent Business Solutions is a leading Indian IT firm pursuing
              innovative and transformative technology solutions for businesses
              across India, Europe, and the United States. With our strong
              technical expertise, industry knowledge, and commitment to client
              success, we have established ourselves as a trusted partner for
              organizations seeking to harness the power of technology to drive
              growth and efficiency. At Coherent, we offer a comprehensive range
              of IT services and solutions tailored to meet the unique needs of
              businesses operating in Indian, European, and US markets. Our team
              of highly-skilled professionals is well-versed in the latest
              technologies and industry best practices, ensuring that we deliver
              cutting-edg/Aboute solutions that drive business outcomes.
            </Text>
          </div>
          <div className="self-stretch mt-[120px] md:mt-[40px]">
            <div className="bg-gray-100">
              <div className="flex justify-center p-12 md:p-5 bg-gradient2">
                <div className="flex flex-col items-center w-[93%] mb-[126px]">
                  <div className="h-[5px] w-[5%] bg-white-A700 rounded-sm" />
                  <Text
                    size="5xl"
                    as="p"
                    className="flex w-[100%] md:w-full mt-5 !text-transparent justify-center  text-center bg-gradient1 bg-clip-text"
                  >
                    <span className="text-indigo-900">
                      <>
                        Our &nbsp;
                        <br />
                      </>
                    </span>
                    <span className="text-indigo-900 font-bold">Team</span>
                  </Text>
                  <div className="flex flex-col self-stretch mt-[20px] gap-[107px]">
                    <div className="flex flex-col self-stretch mt-[20px] gap-[107px]">
                      {/* Render first row with two boxes */}
                      <div className="flex justify-center items-center w-full gap-[50px] md:gap-[75px] mt-[20px] md:flex-col">
                        {data.slice(0, 2).map((team, index) => (
                          <div
                            key={index}
                            className="flex justify-center items-center gap-10v"
                          >
                            <div className="self-stretch h-[300px] w-[290px] bg-blue_gray-100_01 md:h-[220px]">
                              <img
                                src={`${process.env.REACT_APP_API_URL}${team.image_url}`}
                                alt={team.image_path}
                                className="h-full w-full object-fill"
                              />
                              <div className="flex flex-col self-center gap-2 w-full text-center mt-4 ">
                                {" "}
                                {/* Center text content */}
                                <div
                                  style={{
                                    color: "#FEFEFE",
                                    fontSize: "22px",
                                    overflow: "hidden",
                                  }}
                                >
                                  {team.name}
                                </div>
                                <div
                                  style={{
                                    color: "#FEFEFE",
                                    fontSize: "16px",
                                    overflow: "hidden",
                                  }}
                                >
                                  {team.designation}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center items-center w-full gap-[55px] md:gap-[76px] md:mt-[108px] mt-[168px] md:flex-col">
                    {data.slice(2, 6).map((team, index) => (
                      <div
                        key={index}
                        className="flex justify-center items-center gap-8"
                      >
                        <div className="self-stretch h-[300px] w-[290px] bg-blue_gray-100_01 md:h-[220px]">
                          <img
                            src={`${process.env.REACT_APP_API_URL}${team.image_url}`}
                            alt={team.name}
                            className="h-full w-full object-cover"
                          />
                          <div className="flex flex-col self-center gap-2 w-full text-center mt-4">
                            {" "}
                            {/* Center text content */}
                            <div
                              style={{
                                color: "#FEFEFE",
                                fontSize: "22px",
                                overflow: "hidden",
                              }}
                            >
                              {team.name}
                            </div>
                            <div
                              style={{
                                color: "#FEFEFE",
                                fontSize: "16px",
                                overflow: "hidden",
                              }}
                            >
                              {team.designation}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center p-12 md:p-5 ">
            <div className="flex flex-col items-center w-[100%] ">
              <div className="h-[5px] w-[6%] justify-center  text-center bg-gradient1 rounded-sm" />

              <Text
                size="5xl"
                as="p"
                className="flex w-[10%] md:w-full mt-5 !text-transparent justify-center  text-center bg-gradient1 bg-clip-text"
              >
                <span className="text-indigo-900">
                  <>
                    Our &nbsp;
                    <br />
                  </>
                </span>
                <span className="text-indigo-900 font-bold">&nbsp;Clients</span>
              </Text>
              <div className="flex flex-wrap justify-center md:flex-col">
                {/* First Row */}
                <div className="flex justify-center items-center w-full md:w-auto gap-[150px] md:gap-[32px] md:mt-[48px] mt-[68px] md:flex-col">
                  <Img
                    src="images/img_bvg_logo_1.png"
                    alt="bvglogo"
                    className="w-[15%] h-auto md:w-[80px] md:h-[auto] mt-[18px] object-cover"
                  />
                  <Img
                    src="images/img_awake_logo_1.png"
                    alt="awakelogo"
                    className="w-[12%] h-auto md:w-[80px] md:h-[auto] mt-[7px] mb-[17px] object-cover"
                  />
                  <Img
                    src="images/img_the_lighthouse_logo.png"
                    alt="thelighthouse"
                    className="w-[12%] h-auto md:w-[80px] md:h-[auto] mb-[33px] object-cover"
                  />
                </div>

                {/* Second Row */}
                <div className="flex justify-center items-center w-full md:w-auto gap-[150px] md:gap-[42px] md:flex-col">
                  <Img
                    src="images/img_wayam_logo_1.png"
                    alt="wayamlogo"
                    className="w-[15%] h-auto md:w-[80px] md:h-[auto] mt-[18px] object-cover"
                  />
                  <Img
                    src="images/img_rhfus_1.png"
                    alt="rhfuslogo"
                    className="w-[12%] h-auto md:w-[80px] md:h-[auto] mt-[7px] mb-[17px] object-cover"
                  />
                  <Img
                    src="images/img_thermax_logo_1.png"
                    alt="thermaxlogo"
                    className="w-[12%] h-auto md:w-[80px] md:h-[auto] mb-[33px] object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center w-full mt-[120px] md:mt-[50px] p-[50px] mx-auto md:p-5 bg-gradient4 max-w-[1316px] rounded-[20px]">
            <div className="flex md:flex-col justify-between items-center w-[93%] gap-5">
              <Heading
                size="4xl"
                as="h2"
                className="w-[50%] md:w-full !text-indigo-900 !leading-[55px] md:!leading-[40px] sm:text-2xl md:text-3xl"
              >
                <>
                  Have an Idea? <br />
                  Connect With Our Team!
                </>
              </Heading>
              <div className="h-[204px] w-[25%] relative sm:h-[160px]">
                <Img
                  src="images/img_group_40.png"
                  alt="image_five"
                  className="justify-center h-[204px] w-[46%] left-0 bottom-0 right-0 top-0 m-auto object-cover absolute"
                />
                <Button
                  onClick={handleConnect}
                  size="2xl"
                  variant="gradient"
                  shape="round"
                  color="yellow_900_indigo_600"
                  className="w-full sm:w-6/12 left-0 bottom-0 right-0 top-0 m-auto sm:px-5 tracking-[-0.09px] font-bold min-w-[280px] absolute sm:min-w-[220px] sm:ml-[-60px]"
                >
                  Work with us
                </Button>
              </div>
            </div>
          </div>
          
        </div>
        <Footer />
      </div>
    </>
  );
}
