import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Button, Img } from "../../components";
// import CBSSolutionsPageNavigations from "../../components/CBSSolutionsPageNavigations";
import CBSLandingPageTwoNavigations from "../../components/CBSLandingPageTwoNavigations";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

export default function CBSSolutionspagePage() {
  const navigate = useNavigate();
  const items = [
    "Flexible Integration",
    "High-Quality Video and Audio",
    "Scalable Infrastructure",
    "Customisation Options",
  ];
  const Edu = [
    "Student Information Management",
    "Course and Curricul…ardian Communication",
    "Chat Feature",
    "Online Teaching",
  ];

  const Connect = [
    "Organisation-Level Chat Groups",
    "Form Builder Integration",
    "Scalable and Flexible",
    "User-Friendly Interface",
    "Affordable Pricing",
  ];

  const Consultancy = [
    "Project Management",
    "Product Development",
    "Cloud Services",
    "IOT Solutions",
    "Customized Solutions",
  ];

  const Care = [
    "Centralized Ticket Management",
    "Customizable Ticket Workflows",
    "Automated Routing and Assignment",
    "Real-Time Collaboration",
    "Integration Capabilities",
  ];
  const Ai = [
    "Lorem Ipsum is simply",
    "Lorem Ipsum is simply",
    "Lorem Ipsum is simply",
  ];
  const handelContact = () => {
    navigate("/cbscontactpage");
  };
  const handelHome = () => {
    navigate("/");
  };
  const [teamImages, setTeamImages] = useState([]); // State to store team images
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchTeamImages = async () => {
      try {
        // Fetch data from the API (assuming it returns an array of image URLs)
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/solutions/`
        );
        setData(response.data[0]);
        console.log("djaango", data);
        // setData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching team images:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchTeamImages(); // Call the fetch function
  }, []);
  return (
    <>
      <Helmet>
        <title>Coherentbs</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col w-full gap-[70px] md:gap-[10px] overflow-auto bg-white-A700">
        <header className="flex justify-between items-center p-[23px] sm:p-5 bg-white-A700">
          <div className="flex justify-center w-[19%] mt-2 sm:justify-normal">
            <Link to="/">
              <div
                className="flex justify-between w-full gap-5 sm:gap-2"
                onClick={handelHome}
              >
                <Img
                  src="images/img_cbs_logo_1.png"
                  alt="cbslogoone_one"
                  className="w-[45%] object-contain"
                />
                <div className="flex flex-col">
                  <Heading size="s" as="h5" className="tracking-[4.00px]">
                    COHERENT
                  </Heading>
                  <Text size="md" as="p" className="tracking-[-0.08px]">
                    Business Solutions
                  </Text>
                </div>
              </div>
            </Link>
          </div>
          <CBSLandingPageTwoNavigations
            className="flex sm:flex-col justify-evenly items-center w-[37%] mt-2 mb-[15px] mr-[9px] gap-5"
            style={{ alignItem: "center" }}
          />
        </header>
        <div className="flex flex-col items-center w-full mx-auto md:p-5 max-w-[1220px]">
          <div className="flex flex-col items-center w-[93%] md:w-full gap-[49px]">
            <div className="flex flex-col items-center gap-[21px]">
              <Heading
                size="3xl"
                as="h1"
                className="!text-transparent !font-poppins text-center bg-gradient1 bg-clip-text sm:text-2xl md:text-2xl"
              >
                Empowering your enterprise Our Innovative IT solutions
              </Heading>
              <Text
                size="md"
                as="p"
                className="text-center !font-medium"
              ></Text>
            </div>
            <div className="self-stretch h-[242px] relative visible sm:hidden md:hidden">
              <div className="h-[200px] w-[94%] bottom-0 right-0 left-0 m-auto bg-[#EEEFF2] backdrop-opacity-[0.01] blur-[114.00px] absolute rounded-[10px]" />
              <div className="flex md:flex-col justify-between w-full top-0 right-0 left-0 gap-5 p-[37px] m-auto sm:p-5 bg-white-A700_01 absolute rounded-[10px]">
                <div className="flex sm:flex-col justify-between items-center w-[59%] md:w-full ml-[29px]">
                  <div className="flex justify-between items-center w-[48%] sm:w-full gap-5">
                    <Button
                      color="red_50"
                      size="xl"
                      className="w-[50px] rounded-[27px]"
                    >
                      <Img src="images/img_heroicons_sm_user.svg" />
                    </Button>
                    <div className="flex flex-col items-start gap-1.5 ">
                      <Heading size="xl" as="h2">
                        900+
                      </Heading>
                      <Text size="3xl" as="p">
                        Convene Users
                      </Text>
                    </div>
                    <div className="h-[55px] w-[2px] bg-blue_gray-50" />
                  </div>
                  <div className="flex justify-evenly items-center w-[40%] sm:w-full gap-5">
                    <Button
                      color="red_50"
                      size="xl"
                      className="self-end w-[55px] rounded-[27px]"
                    >
                      <Img src="images/img_gridicons_location.svg" />
                    </Button>
                    <div className="flex flex-col items-start gap-1.5">
                      <Heading size="xl" as="h2">
                        30+
                      </Heading>
                      <Text size="3xl" as="p">
                        Countries
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center w-[28%] md:w-full mr-[39px] gap-5">
                  <div className="h-full w-[2px] bg-blue_gray-50" />
                  <div className="flex justify-between items-center w-[66%] gap-5">
                    <Button
                      color="red_50"
                      size="xl"
                      className="w-[55px] rounded-[27px]"
                    >
                      <Img src="images/img_bx_bxs_server.svg" />
                    </Button>
                    <div className="flex flex-col items-start gap-1.5">
                      <Heading size="xl" as="h4">
                        5+
                      </Heading>
                      <Text size="3xl" as="p">
                        Products
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center w-[39%] md:w-full mt-[17px]">
            <div className="flex flex-col items-center w-full gap-5">
              <div className="h-[5px] w-[15%] bg-gradient1 rounded-sm" />
              <Text
                size="5xl"
                as="p"
                className="flex !text-indigo-900 text-center"
              >
                <span className="text-indigo-900">
                  <>
                    Our
                    <br />
                  </>
                </span>
                <span className="text-indigo-900 font-bold">
                  &nbsp; Solutions
                </span>
              </Text>
            </div>
          </div>
          <div className="flex md:flex-col justify-between items-center w-[96%] md:w-full mt-[88px] gap-5">
            <div className="h-[434px] w-[100%] relative">
              <div className="justify-center h-[433px] w-full left-0 bottom-0 right-0 top-0 m-auto absolute">
                <Img
                  src="images/img_chjpdmf0zs9sci9.png"
                  alt="chjpdmf0zs9scin"
                  className="justify-center h-[433px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute"
                />
                <Img
                  src="images/img_demo_2.png"
                  alt="demotwo_one"
                  className="h-[187px] w-[51%] top-[16%] right-0 left-0 m-auto object-cover absolute"
                />
              </div>
              <div className="flex justify-center w-full h-full left-0 bottom-0 right-0 top-0 px-14 py-[179px] m-auto md:p-5 bg-black-900_7f absolute">
                <Img
                  src="images/img_play_circle.svg"
                  alt="image"
                  className="h-[75px] w-[75px]"
                />
              </div>
            </div>

            <div className="flex flex-col items-start w-[100%] md:w-full ml-10">
              <div className="flex flex-col self-stretch gap-[30px]">
                <Heading size="2xl" as="h2" className="!text-indigo-900">
                  Convene Video Calling
                </Heading>
                <Text size="xl" as="p" className="!leading-[30px]">
                  We are excited to introduce our innovative video conferencing
                  solution that can seamlessly integrate with your existing web
                  or mobile applications via API.
                </Text>
              </div>
              <div className="flex w-[100%] md:w-full mt-[15px]">
                <div className="flex flex-col gap-[15px]">
                  {items.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-center items-start gap-2.5"
                    >
                      {/* Render image if it's the first item */}
                      <Img
                        src="images/img_bx_bxs_check_circle.svg"
                        alt="bxbxscheck"
                        className="h-[26px]"
                      />
                      <Text
                        as="p"
                        className="mt-0.5 w-[100%]"
                        style={{ fontSize: "18px" }}
                      >
                        {item}
                      </Text>
                    </div>
                  ))}
                </div>
              </div>
              <a
                href="https://www.convenevc.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  color="yellow_900"
                  size="lg"
                  shape="round"
                  className="mt-11 sm:px-5 font-semibold min-w-[153px]"
                >
                  View Details
                </Button>
              </a>
            </div>
          </div>
          <div className="flex md:flex-col self-stretch justify-between items-start mt-[88px] md:mt-[40px] gap-5 md:flex-col-reverse">
            <div className="flex flex-col items-start w-[55%] md:w-full mt-[19px] gap-[34px]">
              <div className="self-stretch ml-5">
                <div
                  className="flex flex-col gap-[35px] "
                  styel={{ border: "2px solid black" }}
                >
                  <div className="flex w-[100%] md:w-full ">
                    <Heading size="2xl" as="h2" className="!text-indigo-900">
                      Convene Connect
                    </Heading>
                  </div>
                  <Text size="xl" as="p">
                    We're dedicated to simplifying communication and enhancing
                    collaboration within organizations. Our comprehensive chat
                    application offers cutting-edge features designed to
                    streamline teamwork and boost productivity
                  </Text>
                  {/* <Text size="xl" as="p" className="w-[82%] md:w-full">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.{" "}
                    </Text> */}
                </div>
                <Heading size="md" as="h6" className="!text-indigo-900 mt-7">
                  Key Points
                </Heading>

                <div className="flex w-[100%] md:w-full mt-[15px]">
                  <div className="flex flex-col gap-[13px]">
                    {Connect?.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-center items-start gap-2.5"
                      >
                        {/* Render image if it's the first item */}
                        <Img
                          src="images/img_bx_bxs_check_circle.svg"
                          alt="bxbxscheck"
                          className="h-[26px]"
                        />
                        <Text
                          as="p"
                          className="mt-0.5 w-[100%]"
                          style={{ fontSize: "18px" }}
                        >
                          {item}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <Button
                color="yellow_900"
                shape="round"
                className="sm:px-5 font-semibold min-w-[153px] ml-5"
                onClick={handelContact}
              >
                View Details
              </Button>
            </div>
            <div className="flex justify-center w-[60%] md:w-full">
              <div className="flex justify-center items-center w-full">
                <div className="flex-1  ml-10">
                  <div className="h-[433px] relative">
                    <Img
                      src="images/web.svg"
                      alt="appleimac_one"
                      className="justify-center h-[433px] w-full left-0 bottom-0 right-0 top-10 m-auto object-cover absolute"
                    />
                  </div>
                </div>
                <div className="self-end h-[216px] w-[22%] relative">
                  <Img
                    src="images/apple.svg"
                    alt="dashboard_one"
                    className="justify-center h-[216px] w-[84%] left-0 bottom-0 right-0 top-0 m-auto object-cover absolute"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-[98%] md:w-full mt-[88px]">
            <div className="flex md:flex-col justify-between items-center gap-5 ">
              <div className="flex w-[48%] md:w-full ">
                <div className="h-[566px] sm:h-[280px] w-full relative ">
                  <Img
                    src="images/Tickit.svg"
                    alt="image_one"
                    className=" h-[566px] sm:h-[280px]  w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute"
                  />
                </div>
              </div>
              <div className="w-[50%] md:w-full h-[566px] ml-10">
                <div className="flex flex-col gap-[105px]">
                  <div className="flex flex-col gap-[26px]">
                    <div className="flex w-[100%] md:w-full">
                      <Heading size="2xl" as="h2" className="!text-indigo-900">
                        Convene Care
                      </Heading>
                    </div>
                    <Text size="xl" as="p">
                      We're dedicated to simplifying communication and enhancing
                      collaboration within organizations. Our comprehensive chat
                      application offers cutting-edge features designed to
                      streamline teamwork and boost productivity
                    </Text>
                    <Heading
                      size="md"
                      as="h6"
                      className="!text-indigo-900 mt-3"
                    >
                      Key Points
                    </Heading>
                    <div className="flex w-[100%] md:w-full mt-[15px]">
                  <div className="flex flex-col gap-[13px]">
                    {Care?.map((item, index) => (
                      <div
                        key={index}
                        className="flex justify-center items-start gap-2.5"
                      >
                        {/* Render image if it's the first item */}
                        <Img
                          src="images/img_bx_bxs_check_circle.svg"
                          alt="bxbxscheck"
                          className="h-[26px]"
                        />
                        <Text
                          as="p"
                          className="mt-0.5 w-[100%]"
                          style={{ fontSize: "18px" }}
                        >
                          {item}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>

                    <div className="flex flex-col items-start w-full">
                      <a
                        href="https://care.convenevc.com/"
                        target="_blank"
                        className="w-[66%] md:w-full mr-3.5"
                      >
                        <Button
                          color="yellow_900"
                          shape="round"
                          className="mt-[4px] sm:px-5 font-semibold min-w-[153px]"
                        >
                          View Details
                        </Button>
                        {/* <Text size="md" as="p" className="!text-white-A700_01 !font-poppins">
                              Read more
                            </Text> */}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex md:flex-col justify-between items-center mt-[88px] md:mt-[89px] gap-5 md:flex-col-reverse">
              <div className="flex flex-col items-start w-[44%] md:w-full">
                <div className="flex flex-col self-stretch gap-[38px]">
                  <div className="flex w-[3=25%] md:w-full">
                    <Heading
                      size="2xl"
                      as="h2"
                      className="w-[70%] md:w-full !text-indigo-900"
                    >
                      Consultancy
                    </Heading>
                  </div>
                  <Text size="lg" as="p" className="!font-poppins">
                    Your Trusted IT Consultancy Partner{" "}
                  </Text>
                  <Text size="lg" as="p" className="!font-poppins">
                    We specialize in providing comprehensive IT consulting
                    services to help businesses thrive in today's digital
                    landscape. With expertise in Project Management, Product
                    Development, and Cloud Services, we're committed to
                    delivering innovative solutions tailored to your unique
                    needs.
                  </Text>
                </div>
                <div className="flex flex-col w-[100%] md:w-full mt-10">
                  <div className="flex flex-col gap-[22px]">
                    {Consultancy.map((Consultancy, index) => (
                      <div
                        key={index}
                        className="flex justify-center items-start gap-2.5"
                      >
                        {/* Render image if it's the first item */}
                        <Img
                          src="images/img_bx_bxs_check_circle.svg"
                          alt="bxbxscheck"
                          className="h-[26px]"
                        />
                        <Text
                          as="p"
                          className="mt-0.5 w-[100%]"
                          style={{ fontSize: "18px" }}
                        >
                          {Consultancy}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>
                <Button
                  size="lg"
                  color="yellow_900"
                  shape="round"
                  className="mt-[77px] sm:px-5 font-semibold min-w-[153px]"
                >
                  View Details
                </Button>
              </div>

              <Img
                src="images/img_rectangle_283.png"
                alt="image_four"
                className="w-[48%] md:w-full sm:h-[280px] object-cover justify-between items-center "
                style={{ border: "10px olid black" }}
              />
            </div>
            <div className="flex md:flex-col justify-between w-[100%] items-center mt-[88px] md:mt-[49px] gap-5">
              <div className="h-[583px] sm:h-[280px] w-[100%] relative " s>
                <Img
                  src="images/awakescreen.svg"
                  alt="laptopondesk"
                  className="justify-center h-[583px] w-full sm:h-[280px] left-0 bottom-0 right-0 top-0 m-auto object-cover absolute"
                />
              </div>
              <div className="flex flex-col items-start w-[100%] md:w-full ml-10 h-[583px]">
                <div className="flex flex-col self-stretch gap-[3px]">
                  <Heading
                    size="2xl"
                    as="h2"
                    className="w-[90%] md:w-full !text-indigo-900"
                  >
                    Enterprise Event Management System
                  </Heading>
                  <Text size="xl" as="p" className="!leading-[187.5%] w-[100%]">
                    We specialize in providing comprehensive IT consulting
                    services to help businesses thrive in today's digital
                    landscape. With expertise in Project Management, Product
                    Development, and Cloud Services, we're committed to
                    delivering innovative solutions tailored to your unique
                    needs.{" "}
                  </Text>
                </div>
                <div className="flex w-[100%] md:w-full mt-12">
                  <div className="flex flex-col gap-[23px]">
                    {Edu.map((Edu, index) => (
                      <div
                        key={index}
                        className="flex justify-center items-start gap-2.5"
                      >
                        {/* Render image if it's the first item */}
                        <Img
                          src="images/img_bx_bxs_check_circle.svg"
                          alt="bxbxscheck"
                          className="h-[26px]"
                        />
                        <Text as="p" className="mt-0.5 w-[100%]">
                          {Edu}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>
                <Button
                  color="yellow_900"
                  size="lg"
                  shape="round"
                  className="mt-6 sm:px-5 font-semibold min-w-[153px]"
                  onClick={handelContact}
                >
                  View Details
                </Button>
              </div>
            </div>

            <div className="flex md:flex-col justify-between mt-[88px] md:mt-[49px] gap-5">
              <div className="flex flex-col items-start w-[37%] md:w-full">
                <div className="flex flex-col self-stretch gap-[3px]">
                  <Heading size="2xl" as="h3" className="!text-indigo-900">
                    AI and Cloud Solutions
                  </Heading>
                  <Text size="xl" as="p" className="!leading-[187.5%]">
                    AIaaS provides cloud-based services, allowing businesses to
                    experiment with AI without large initial investments. It
                    offers machine learning algorithms, data pattern
                    recognition, natural language processing, predictive
                    analytics, and more. Think of it as AI without needing an
                    extensive data science team
                  </Text>
                </div>
                <div className="flex w-[42%] md:w-full mt-6">
                  <div className="flex flex-col gap-[21px]">
                    {Ai.map((Ai, index) => (
                      <div
                        key={index}
                        className="flex justify-center items-start gap-2.5"
                      >
                        {/* Render image if it's the first item */}
                        {/* <Img src="images/img_bx_bxs_check_circle.svg" alt="bxbxscheck" className="h-[26px]" />
            <Text as="p" className="mt-0.5">
              {Ai}
            </Text> */}
                      </div>
                    ))}
                  </div>
                </div>
                <Button
                  color="yellow_900"
                  size="lg"
                  shape="round"
                  className="mt-11 sm:px-5 font-semibold min-w-[153px]"
                  onClick={handelContact}
                >
                  View Details
                </Button>
              </div>
              <Img
                src="images/img_rectangle_284.png"
                alt="image_five"
                className="w-[48%] md:w-full object-cover"
              />
            </div>
          </div>
        </div>
        <Footer className="mt-[69px]" />{" "}
      </div>
    </>
  );
}
