import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types"; 
import { Link } from "react-router-dom";
export default function CBSContactpagePage() {
  const shapes = {
    round: "rounded-[5px]",
  };

  const variants = {
    gradient: {
      yellow_900_indigo_600: "bg-gradient-to-r from-yellow-900 to-indigo-600 text-gray-50",
    },
    fill: {
      yellow_900: "bg-yellow-900 text-white",
      white_A700: "bg-white shadow-xs",
    },
  };

  const sizes = {
    xs: "h-[44px] px-[34px] text-base",
    sm: "h-[58px] px-[11px] text-lg",
  };

  const Button = ({
    children,
    className = "",
    leftIcon,
    rightIcon,
    shape,
    variant = "fill",
    size = "xs",
    color = "white_A700",
    ...restProps
  }) => {
    return (
      <button
        className={`${className} flex items-center justify-center text-center cursor-pointer ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
        {...restProps}
      >
        {leftIcon && <span className="mr-2">{leftIcon}</span>}
        {children}
        {rightIcon && <span className="ml-2">{rightIcon}</span>}
      </button>
    );
  };

  Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    shape: PropTypes.oneOf(["round"]),
    size: PropTypes.oneOf(["xs", "sm"]),
    variant: PropTypes.oneOf(["gradient", "fill"]),
    color: PropTypes.oneOf(["yellow_900_indigo_600", "yellow_900", "white_A700"]),
  };

  const Text = ({ children, className = "", as, size = "xl", ...restProps }) => {
    const Component = as || "p";

    const textSizes = {
      xs: "text-[13px] font-medium",
      lg: "text-base font-normal",
      s: "text-sm font-medium",
      "2xl": "text-xl font-normal",
      "3xl": "text-3xl font-normal md:text-[28px] sm:text-[26px]",
      xl: "text-lg font-normal",
      md: "text-[15px] font-normal",
    };

    return (
      <Component className={`text-white-A700 font-inter ${className} ${textSizes[size]}`} {...restProps}>
        {children}
      </Component>
    );
  };

  const Img = ({ className, src = "defaultNoData.png", alt = "testImg", ...restProps }) => {
    return <img className={className} src={src} alt={alt} {...restProps} loading={"lazy"} />;
  };

  return (
    <>
      <Helmet>
        <title>Coherentbs</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full h-full bg-white-A700">
        <footer className="mt-[59px] py-5 sm:mt-[40px]">
          <div className="bg-gradient1">
            <div className="flex flex-col items-center justify-center gap-[47px] my-[1px] sm:gap-[9px]">
              <div className="w-full p-2.5  md:p-5 max-w-[1376px]">
                <div className="flex md:flex-col justify-between gap-5">
                    <div className="flex justify-center items-start w-[30%] md:w-full gap-2.5 p-6 sm:p-5 bg-blue_gray-100_33 rounded-[5px]"  style={{ backgroundColor: "rgb(217, 217, 217 ,0.2)",marginTop:"2%" }}>
                      <Img src="images/marker-pin-01.svg" alt="markerpinone" className="h-[24px] w-[24px] mt-2" />  
                    <Text as="p" className="w-[92%] leading-[30px] sm:leading-[18px]">
                      Unit Number 11, Lokmanya House, Next to CNG Pump. Vanaz, Kothrud, 411038
                    </Text>
                  </div>
                  <div className="flex w-[30%] md:w-full p-6 sm:p-5 bg-blue_gray-100_33 rounded-[5px]" style={{ backgroundColor: "rgb(217, 217, 217 ,0.2)" ,marginTop:"2%" }}>
                    <div className="flex items-start w-[80%] md:w-full mb-[30px] ml-4 gap-[9px] md:ml-0">
                      <Img src="images/marker-pin-01.svg" alt="markerpinone" className="h-[24px] w-[24px] mt-1.5" />
                      <Text as="p" className="w-[91%] leading-[30px] sm:leading-[18px]">
                        12 Bernice Street, Edison New Jersey 08820
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-col items-start w-[30%] md:w-full gap-3.5 p-[18px] bg-blue_gray-100_33 rounded-[5px]" style={{ backgroundColor: "rgb(217, 217, 217 ,0.2)" ,marginTop:"2%" }}>
                    <div className="flex items-center ml-[22px] gap-3.5 md:ml-0">
                      <Img src="images/mail-02.svg" alt="mailtwo_one" className="self-start h-[24px] w-[24px]" />
                      <Text as="p" className="self-end sm:leading-[18px]">
                        enquiry@coherentbs.com
                      </Text>
                    </div>
                    <div className="flex items-center ml-[22px] gap-3.5 md:ml-0">
                      <Img src="images/Icon.svg" alt="phone_one" className="h-[24px] sm:h-[18px] w-[24px] sm:leading-[18px]" />
                      <Text as="p" className="sm:h-[22px]">+ 91 77559 50900</Text>
                    </div>
                    <div className="flex items-center ml-[22px] gap-3.5 md:ml-0">
                      <Img src="images/Icon.svg" alt="phone_three" className="h-[24px]  sm:h-[18px] w-[24px]" />
                      <Text as="p">+ 1-972-510-4728</Text>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-start w-[51%] md:w-full gap-5 md:p-3">
              <Link to="/cbsaboutpage">
                  <Text as="p" className="!font-poppins text-center">
                    About Us
                  </Text>
            </Link>
                <div className="h-[9px] w-[9px] mt-[9px] bg-white-A700 rounded" />
                <Link to="/cbssolutionspage">
                  <Text as="p" className="!font-poppins text-center">
                    Solutions
                  </Text>
               </Link>
                <div className="h-[9px] w-[9px] mt-[9px] bg-white-A700 rounded" />
                <Link to="/cbsgallerypage">
                  <Text as="p" className="!font-poppins text-center">
                    Gallery
                  </Text>
               </Link>
                <div className="h-[9px] w-[9px] mt-[9px] bg-white-A700 rounded" />
                <Link to="/cbscontactpage">
                  <Text as="p" className="!font-poppins text-center">
                    Contact Us
                  </Text>
             </Link>
              </div>
              <div className="flex gap-7 ">
              <a href="https://www.convenevc.com" target="_blank" rel="noopener noreferrer">
                <Button size="sm" className="w-[59px] rounded-[29px]">
                  <Img src="images/img_group_2.png" />
                </Button>
                </a>
                <a href="https://www.linkedin.com/company/coherent-business-solutions/" target="_blank" rel="noopener noreferrer">
                <Button size="sm" className="w-[59px] rounded-[29px]">
                  <Img src="images/img_link.svg" />
                </Button>
                </a>
              </div>
              <div className="self-stretch h-px w-full bg-white-A700" />
              <div className="flex">
                <Text size="md" as="p" className="self-end tracking-[-0.07px]">
                  © 2023 Copyright by Coherent Business Solutions. All rights reserved.
                </Text>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
