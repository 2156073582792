import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";

import NotFound from "pages/NotFound";
import CBSLandingpageTwo from "pages/CBSLandingpageTwo";
import CBSaboutpage from "pages/CBSaboutpage";
import CBSSolutionspage from "pages/CBSSolutionspage";
import CBSGallerypage from "pages/CBSGallerypage";
import CBSContactpage from "pages/CBSContactpage";

const ProjectRoutes = () => {
  return (
    <HashRouter> {/* Wrap your routes with HashRouter */}
      <Routes>
        <Route path="/" element={<CBSLandingpageTwo />} />
        <Route path="cbsaboutpage" element={<CBSaboutpage />} />
        <Route path="cbssolutionspage" element={<CBSSolutionspage />} />
        <Route path="cbsgallerypage" element={<CBSGallerypage />} />
        <Route path="cbscontactpage" element={<CBSContactpage />} />
        <Route path="*" element={<NotFound />} /> 
      </Routes>
    </HashRouter>
  );
};

export default ProjectRoutes;