import React from "react";
import PropTypes from "prop-types";

const shapes = {
  square: "rounded-[0px]",
  round: "rounded-[5px]",
};
const variants = {
  fill: {
    red_50: "bg-red-50",
    yellow_900: "bg-yellow-900 text-white-A700",
    white_A700: "bg-white-A700 shadow-xs",
  },
  gradient: {
    yellow_900_indigo_600: "bg-gradient text-gray-50",
  },
};
const sizes = {
  sm: "h-[40px] px-[3px]",
  "2xl": "h-[61px] px-[35px] text-lg",
  lg: "h-[48px] px-7 text-base",
  xl: "h-[55px] px-[15px]",
  xs: "h-[34px] px-[7px]",
  md: "h-[44px] px-[34px] text-base",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape = "",
  variant = "fill",
  size = "md",
  color = "white_A700",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex items-center justify-center text-center cursor-pointer ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["square", "round"]),
  size: PropTypes.oneOf(["sm", "2xl", "lg", "xl", "xs", "md"]),
  variant: PropTypes.oneOf(["fill", "gradient"]),
  color: PropTypes.oneOf(["red_50", "yellow_900", "white_A700", "yellow_900_indigo_600"]),
};

export { Button };
