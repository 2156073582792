import React from "react";

const sizes = {
  "3xl": "text-[33px] font-semibold leading-[180.5%]",
  "2xl": "text-[32px] font-semibold",
  xl: "text-[25px] font-bold leading-[31px]",
  "5xl": "text-[40px] font-semibold",
  "4xl": "text-[35px] font-bold leading-[43px]",
  "7xl": "text-6xl font-bold leading-[73px]",
  s: "text-xl font-bold",
  md: "text-[22px] font-bold leading-[27px]",
  "6xl": "text-[50px] font-extrabold leading-[61px]",
  xs: "text-lg font-semibold",
  lg: "text-2xl font-bold leading-[30px]",
};

const Heading = ({ children, className = "", size = "xs", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-indigo-600 font-inter ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
